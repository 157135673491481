import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Styles from './css/iphonecases.module.css'
import { Helmet } from "react-helmet"
import Sidebar from "../components/sidebar/sidebar"
import SamsungImages from './css/samsungcases.module.css'

const SamsungCases = () => (
  <Layout>

    <Helmet>
      <title>Colorful Samsung Cases</title>
      <meta name="description" content="Find a Large Selection of Colorful Samsung Cases with unique and high quality designs." />
      <meta name="keywords" content="colorful phone case, Samsung Phone Cases, Colorful Samsung Phone Cases, Bright Samsung Phone Cases, Psychedlic Samsung Phone Cases, samsung trippy phone cases, samsung psychedelic phone cases, trippy samsung phone cases" />
      <meta property="og:image" content="../images/CustomogThumbnail.png" />
    </Helmet>
    <div className={Styles.breadcrumb}><Link className={Styles.breadcrumbLink} to="/">Home</Link>{' > '} Samsung Galaxy Cases</div>
    <div className={Styles.mainDiv}>
      <Sidebar />


      <div className={Styles.container}>
        <h1 className={Styles.containerHeader}>Choose Your Samsung Model</h1>
         {/* Samsung S21*/}
         <div className={Styles.catOptionContainer}>
            <div className={SamsungImages.s21}></div>
              <div>
                <Link to="/samsung-galaxy-s21-cases" className={Styles.branddivVariant}>
                  <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S21</h3>
                </Link>
                <Link to="/samsung-galaxy-s21-plus-cases" className={Styles.branddivVariant}>
                  <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S21 Plus</h3>
                </ Link>
                <Link to="/samsung-galaxy-s21-ultra-cases" className={Styles.branddivVariant}>
                  <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S21 Ultra</h3>
                </ Link>
            
              </div>
            </div>

        {/* Samsung S20*/}
        <div className={Styles.catOptionContainer}>
            <div className={SamsungImages.s20}></div>
              <div>
                <Link to="/samsung-galaxy-s20-cases" className={Styles.branddivVariant}>
                  <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S20</h3>
                </Link>
                <Link to="/samsung-galaxy-s20-plus-case" className={Styles.branddivVariant}>
                  <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S20 Plus</h3>
                </ Link>
                <Link to="/samsung-galaxy-s20-ultra-cases" className={Styles.branddivVariant}>
                  <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S20 Ultra</h3>
                </ Link>
            
              </div>
            </div>

          {/* Samsung S10*/}
          <div className={Styles.catOptionContainer}>
          <div className={SamsungImages.s10}></div>
          <div>
            <Link to="/samsung-galaxy-s10-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S10</h3>
            </Link>
            <Link to="/samsung-galaxy-s10-plus-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S10 Plus</h3>
            </ Link>
            <Link to="/samsung-galaxy-s10e-cases" className={Styles.branddivVariant}>
              <h3 className={Styles.brandtitlevariant}>Samsung Galaxy S10E</h3>
            </ Link>
         
          </div>
        </div>

      </div>
    </div>
  </Layout>
)

export default SamsungCases
